<template>
  <div
    class="rounded-md flex flex-col h-full group overflow-hidden transition-all shadow"
    :class="{
      'bg-los-blue-lightest': active,
      'bg-los-pink-lightest': !active,
      'w-full': listView,
      'w-52 sm:w-60 md:w-72': !listView,
    }"
  >
    <figure
      v-if="image"
      class="max-h-40 h-full w-full aspect-square overflow-hidden relative flex items-center"
    >
      <NuxtImg
        class="w-full h-full object-cover group-hover:scale-105 transition-all"
        :alt="image.alternativeText"
        :src="useStrapiImage(image, 'small')"
        loading="lazy"
      />
      <div
        class="absolute inset-0 pointer-events-none shadow-[inset_0px_-130px_80px_-69px_rgba(0,0,0,0.65)]"
      ></div>
      <figcaption
        class="font-medium absolute bottom-0 px-2 sm:px-4 pb-3 text-sm sm:text-base md:text-xl text-white break-words w-full"
      >
        {{ typeName }}
      </figcaption>
    </figure>
    <div class="flex flex-col justify-between px-2.5 sm:px-5 py-6 w-full grow">
      <div class="text-sm md:text-base">
        <div class="mb-1">
          <span class="sr-only">{{ typeName }}.</span>
          <span class="font-bold">Begint op: </span>
          <span class="hidden md:inline">{{ date }}</span
          ><span class="md:hidden">{{ dateMobile }}</span>
        </div>
        <div v-if="!hideLessons">
          <span class="font-bold">Aantal lessen:</span> {{ lessonsCount }}
        </div>
      </div>
      <div
        class="flex flex-row-reverse gap-y-2 justify-between gap-x-8 w-full border-t-2 border-dashed mt-5 pt-5"
        :class="{
          'flex-wrap items-center gap-y-2': listView,
          'justify-start md:items-center flex-col md:flex-row text-sm':
            !listView,
          'border-los-blue-base': active,
          'border-los-pink-base': !active,
        }"
      >
        <div class="text-xs py-1">
          <template v-if="availablePositions > 0 && availablePositions <= 2"
            >Nog
            <span class="font-semibold">{{ availablePositions }}</span>
            plek<template v-if="availablePositions !== 1">ken</template>
            vrij<span class="sr-only">.</span></template
          >
          <template v-else-if="availablePositions > 2"> </template>
          <template v-else>Uitverkocht</template>
        </div>
        <div v-if="availablePositions > 0">
          <div
            class="bg-los-pink-darkest text-white py-1 px-2 rounded-full inline-block group-hover:underline text-sm font-bold"
          >
            Meld je aan
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs';

const props = defineProps({
  workshop: {
    type: Object,
    required: true,
  },
  typeName: {
    type: String,
    required: true,
  },
  image: {
    type: Object,
    default: null,
  },
  active: {
    type: Boolean,
    default: false,
  },
  listView: {
    type: Boolean,
    default: false,
  },
  hideLessons: {
    type: Boolean,
    default: false,
  },
});

const date = computed(() => {
  return dayjs(props.workshop.start_date_time).format('D MMM HH:mm');
});

const dateMobile = computed(() => {
  return dayjs(props.workshop.start_date_time).format('D MMM');
});

const availablePositions = computed(() => {
  return props.workshop.spots - (props.workshop.participants?.length ?? 0);
});

const lessonsCount = computed(() => {
  return props.workshop.extra_dates.length + 1;
});
</script>

<style lang="scss" scoped></style>
